#page {
  &--login,
  &--forgot-password {
    height: 100%;
    overflow-y: auto;
  }

  &--account {
    .profile {
      .sb-avatar {
        margin: -120px auto 40px auto;
        box-shadow: $box-shadow-6;
      }
    }
  }
}
