$enable-negative-margins: true;

$primary-font-family: 'Montserrat', Helvetica, sans-serif;

$header-height: 65px;
$sidebar-width: 256px;
$base-font-size: 16px;

$white: #fff !default;
$gray-50: #fbfbfb !default;
$gray-100: #f5f5f5 !default;
$gray-200: #eeeeee !default;
$gray-300: #e0e0e0 !default;
$gray-400: #bdbdbd !default;
$gray-500: #9e9e9e !default;
$gray-600: #757575 !default;
$gray-700: #616161 !default;
$gray-800: #4f4f4f !default;
$gray-900: #262626 !default;
$black: #000 !default;

$primary: #1976d2 !default;
$secondary: #000034 !default;
$success: #00b74a !default;
$info: #39c0ed !default;
$warning: #ffa900 !default;
$danger: #f93154 !default;

$primary-bg-color: $gray-200;
$primary-font-color: $gray-900;

$box-shadow-none: none !default;
$box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07) !default;

$box-shadow-1: 0 0px 5px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-2: 0 0px 10px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-3: 0 0px 15px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-4: 0 0px 20px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-5: 0 0px 30px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-6: 0 0px 40px 0 rgba(0, 0, 0, 0.05) !default;

$border-radius: 4px;

// Break points
$smallScreen: 576px;
$mediumScreen: 768px;
$largeScreen: 992px;
$xLargeScreen: 1200px;
$xxLargeScreen: 1400px;
