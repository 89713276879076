@import "~bootstrap/scss/bootstrap";

body {
  background-color: #fff;
  width           : 100%;
  height          : 100vh;
  overflow        : hidden;
  font-size       : $base-font-size;
  font-family     : $primary-font-family;
}

#root {
  width     : 100%;
  height    : 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position  : relative;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

span.form-error {
  left       : 50%;
  transform  : translateX(-50%);
  top        : 0;
  font-size  : 14px;
  font-weight: 500;
}

.loading-indicator {
  position        : fixed;
  height          : 5px;
  width           : 100%;
  top             : 0;
  left            : 0;
  z-index         : 2000;
  background-color: tint($primary, 75%);
  overflow        : hidden;

  .line {
    position        : absolute;
    opacity         : 0.4;
    background-color: $primary;
    width           : 150%;
    height          : 5px;
  }

  .subline {
    position        : absolute;
    background-color: $primary;
    height          : 5px;
  }

  .inc {
    animation: increase 2s infinite;
  }

  .dec {
    animation: decrease 2s 0.5s infinite;
  }

  @keyframes increase {
    from {
      left : -5%;
      width: 5%;
    }

    to {
      left : 130%;
      width: 100%;
    }
  }

  @keyframes decrease {
    from {
      left : -80%;
      width: 80%;
    }

    to {
      left : 110%;
      width: 10%;
    }
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity   : 1;
  transition: opacity 300ms ease-in;
}

.fade-exit.fade-exit-active,
.fade-exit.fade-exit-done {
  position: absolute !important;
  z-index : -1;
  width   : 0px;
  height  : 0px;
  overflow: hidden;

  &>* {
    width : 0px;
    height: 0px;
  }
}

.MuiTableCell-root {
  font-size  : 0.85rem !important;
  font-weight: 500 !important;
}

.MuiTableCell-footer {
  border: 0 !important;
}

.table-toolbar {
  span.applied-filters {
    max-width : 100%;
    overflow-x: auto;
    display   : inline-block;

    span.badge {
      font-weight: 500;
      padding    : 7px 30px 7px 7px;
      position   : relative;
      display    : inline-block;
      margin     : 0px 5px 4px 0px;

      &>svg {
        position : absolute;
        right    : 5px;
        top      : 50%;
        transform: translateY(-50%);
        cursor   : pointer;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.portal,
.filter-drawer {
  &__header {
    box-shadow      : $box-shadow;
    backdrop-filter : blur(6px);
    background-color: rgba(250, 250, 250, 0);
  }
}

.daterangepicker {
  box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
}

// .card.profile {
//   .sb-avatar.profile-picture {
//     border-radius   : 50%;
//     background-color: $gray-50;
//     margin          : -120px auto 40px auto;
//     box-shadow      : $box-shadow-6;
//     overflow        : hidden;
//   }
// }

table {
  &.table {
    &--details {
      &>tbody {
        &>tr {

          &>th,
          &>td {
            padding: 1rem 1rem !important;
          }

          &>th {
            color         : $gray-500 !important;
            font-weight   : 500 !important;
            font-size     : 0.85rem !important;
            vertical-align: middle !important;
            width         : 150px !important;
            border-radius : 7px 0px 0px 7px !important;
          }

          &>td {
            font-weight   : 500 !important;
            color         : $gray-400 !important;
            vertical-align: middle !important;
          }

          &>td {
            &:last-child {
              font-weight   : 600 !important;
              color         : $gray-800 !important;
              vertical-align: middle !important;
              border-radius : 0px 7px 7px 0px !important;
            }
          }

          &:nth-child(odd) {
            &>* {
              background-color: $gray-50 !important;
            }
          }
        }
      }
    }
  }
}

.layout {
  &--blank {
    background-color: $white;

    .header {
      display         : flex;
      align-items     : center;
      justify-content : flex-start;
      min-height      : $header-height;
      background-color: transparent;
    }

    .content {
      height  : 100%;
      overflow: auto;
    }
  }

  &--default {
    background-color: $primary-bg-color;
    color           : $primary-font-color;
    position        : relative;
    height          : 100vh;
    overflow-y      : auto;
    overflow-x      : hidden;

    .header {
      box-shadow      : none;
      min-height      : $header-height;
      backdrop-filter : blur(6px);
      background-color: rgba(250, 250, 250, 0);
    }

    aside {
      position: fixed;
      width   : $sidebar-width;
      left    : 0;
      top     : $header-height;
      height  : calc(100% - #{$header-height});

      .nav {
        a {
          &.nav-item {
            color                     : $gray-600 !important;
            background-color          : $primary-bg-color !important;
            transition                : all 0.3s ease;
            font-weight               : 600;
            height                    : 44px;
            display                   : flex;
            align-items               : center;
            justify-content           : flex-start;
            border-bottom-right-radius: 32px !important;
            border-top-right-radius   : 32px !important;
            margin-top                : 0.375rem !important;
            box-shadow                : none !important;

            &:hover {
              background-color: $gray-300 !important;
            }

            &.active {
              background-color: $primary !important;
              box-shadow      : 0 1px 3px 0 rgba(0, 0, 0, 0.5) !important;
              color           : $white !important;

              &:hover {
                background-color: $primary !important;
                color           : $white !important;
              }
            }
          }
        }
      }
    }

    main {
      margin-top    : 1rem;
      margin-left   : $sidebar-width;
      width         : calc(100% - #{$sidebar-width});
      // min-height : calc(100% - #{$header-height});
    }
  }
}